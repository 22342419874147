import { useCallback, useContext } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { UserRole } from '../enums/userRoles';
import { getAccessToken } from '../../utils';
import { AuthContext } from '../../app/provider';

export interface User {
  userId: string;
  userEmail: string;
  roles: UserRole[];
  userName: string;
}

export function useAuth (): User & {
  login: () => void;
  logout: () => void;
  getAccessToken: () => string | undefined;
  isAuthenticated: boolean;
  isLoading: boolean;
  } {
  const {user, isLoading, isAuthenticated} = useContext(AuthContext);
  const navigate = useNavigate();

  const login = useCallback(() => {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL || ''}/api/auth/login`;
  }, []);

  const logout = useCallback(async () => {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL || ''}/api/auth/logout`;

    axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`
      }
    }).then(_ => {
      Cookies.remove('access_token');
      navigate('/login');
    });
  }, [navigate]);

  return { ...user, isAuthenticated, isLoading, login, logout, getAccessToken };
}
