import React, { PropsWithChildren, useEffect, useState } from 'react'
import { User } from '../common'
import { decodeToken, getAccessToken } from '../utils';

interface AuthState {
    user: User;
    isLoading: boolean;
    isAuthenticated: boolean;
}

export const AuthContext = React.createContext<AuthState>({
  user: {
    userId: '',
    userEmail: '',
    roles: [],
    userName: ''
  },
  isLoading: true, 
  isAuthenticated: false
});

export const AuthProvider = (props: PropsWithChildren) => {
  const [user, setUser] = useState<User>({
    userId: '',
    userEmail: '',
    roles: [],
    userName: ''
  });
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const accessToken = getAccessToken();

    if (accessToken) {
      const decodedToken = decodeToken(accessToken);
      if (decodedToken) {
        setUser({
          userId: decodedToken.id,
          userEmail: decodedToken.email,
          roles: decodedToken.roles || [],
          userName: `${decodedToken.firstName} ${decodedToken.familyName}`
        });
        setIsAuthenticated(true);
      } else {
        // If token can't be decoded, reset user state to unauthenticated state
        setUser({
          userId: '',
          userEmail: '',
          roles: [],
          userName: ''
        });
        setIsAuthenticated(false);
      }
    } else {
      // If there is no token, reset user state to unauthenticated state
      setUser({
        userId: '',
        userEmail: '',
        roles: [],
        userName: ''
      });
      setIsAuthenticated(false);
    }
    setIsLoading(false);
  }, []);
    
  return <AuthContext.Provider value={{
    user, 
    isAuthenticated, 
    isLoading
  }}>
    {props.children}
  </AuthContext.Provider>
}