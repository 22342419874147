import React, { useEffect, useState } from 'react';
import DefaultButton from '../components/button';
import { useTask } from '../../common/hooks/api';
import ItemList from '../components/ItemList';
import { Task, StrippedTask } from '../../common';
import TaskPopup from './createTaskPopup';
import { feedbackToast } from '../components/toasts';

interface TaskOverviewProps {
  onNewTaskClick: () => void;
  setIsPopupVisible: (visible: boolean) => void;
  isPopupVisible: boolean;
  setSelectedTask: (task: Task | null) => void;
}

const TaskOverview = ({ onNewTaskClick, setIsPopupVisible, isPopupVisible, setSelectedTask }: TaskOverviewProps) => {
  const { getAllTasks, deleteTask } = useTask();
  const [tasks, setTasks] = useState<StrippedTask[]>([]);
  const [selectedTask, setSelectedTaskState] = useState<Task | null>(null);

  useEffect(() => {
    getAllTasks()
      .then(fetchedTasks => {
        setTasks(fetchedTasks);
      })
      .catch(error => {
        console.error('Error fetching tasks:', error);
      });
  }, []);

  const handleNewTaskClick = () => {
    setSelectedTask(null);
    onNewTaskClick();
  };

  const handleEditClick = (taskId?: number) => {
    const taskToEdit = tasks.find(task => task.taskId === taskId);
    setSelectedTask(taskToEdit || null);
    setIsPopupVisible(true);
  };

  const handleDeleteClick = (taskId?: number) => {
    deleteTask(taskId)
      .then(() => {
        setTasks(prevTasks => prevTasks.filter(task => task.taskId !== taskId));
        feedbackToast({
          variant: 'success',
          message: 'Ämtli wurde gelöscht.',
          ctaMessage: 'Rückgängig machen?',
          toastId: 'TaskRemoved'
        });
      })
      .catch(error => {
        console.error('Error deleting task:', error);
        feedbackToast({
          variant: 'warn',
          message: 'Ämtli wurde nicht gelöscht.',
          ctaMessage: '',
          toastId: 'TaskNotRemoved'
        });
      });
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
    setSelectedTask(null);
    setSelectedTaskState(null);
  };

  return (
    <div className="flex">
      <div className="container mx-auto">
        <div className="lg:mx-72 md:mx-32 sm:mx-24 mx-4 mt-8">
          <div className="bg-center bg-clip-border h-96 relative">
            <div className="flex justify-between items-center">
              <p className="text-theme-300 font-primary font-bold select-none text-2xl">Ämtli Übersicht</p>
              <DefaultButton onClick={handleNewTaskClick} text="Neues Ämtli hinzufügen" />
            </div>
            <ItemList
              list={tasks}
              onEdit={index => handleEditClick(tasks[index].taskId)}
              onDelete={index => handleDeleteClick(tasks[index].taskId)}
              emptyListNotice="Es gibt noch keine Ämtlis!"
            />
          </div>
        </div>
      </div>
      {isPopupVisible && <TaskPopup onClose={handleClosePopup} task={selectedTask} />}
    </div>
  );
};

export default TaskOverview;
