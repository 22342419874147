import React, { useState, useEffect, ChangeEvent } from 'react';
import { useTask, Task } from '../../common';
import SaveButton from '../components/saveButton';
import ExitButton from '../components/exitButton';
import Dropdown from '../components/dropdown';
import InputField from '../components/inputField';
import { Size } from '../../common/enums/inputFieldSize';
import { feedbackToast } from '../components/toasts';
import dayjs from 'dayjs';

interface InputFieldsState {
  taskName: string;
  requiredPersonnel: string;
  rhythm: string;
  weekday: string;
  description: string;
  duration: string
}

interface NewTaskPopupProps {
  onClose: () => void;
  task?: Task | null;
}

const TaskPopup = ({ onClose, task }: NewTaskPopupProps) => {  
  const initialInputFieldsState: InputFieldsState = {
    taskName: task?.name || '',
    requiredPersonnel: task?.requiredPersonnel.toString() || '1',
    rhythm: task?.rhythm || '0 0 * * *',
    weekday: task ? task.weekday.toString() : dayjs().day() === 0 ? '7' : dayjs().day().toString(),
    description: task?.description || '',
    duration: task?.duration.toString() || '5'
  };
  const [inputFields, setInputFields] = useState<InputFieldsState>(initialInputFieldsState);
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [showWeekdayDropdown, setShowWeekdayDropdown] = useState(false);
  const { createTask, updateTask } = useTask();

  useEffect(() => {
    if (task) {
      setInputFields({
        taskName: task.name,
        requiredPersonnel: task.requiredPersonnel.toString(),
        rhythm: task.rhythm,
        weekday: task.weekday.toString(),
        description: task.description,
        duration: task.duration.toString()
      });
    }
    if (inputFields.rhythm.toString() != '0 0 * * *') {
      setShowWeekdayDropdown(true)
    }
  }, [task]);

  const handleSubmit = () => {
    const taskData = {
      name: inputFields.taskName,
      description: inputFields.description,
      requiredPersonnel: parseInt(inputFields.requiredPersonnel),
      rhythm: inputFields.rhythm,
      weekday: parseInt(inputFields.weekday),
      taskId: task?.taskId || undefined,
      duration: parseInt(inputFields.duration)
    };

    const taskAction = task?.taskId ? updateTask : createTask;
    const TaskToastSuccessMessage = task?.taskId ? 'Ämtli wurde aktualisiert.' : 'Ämtli wurde hinzugefügt.';

    taskAction(taskData)
      .then(_ => {
        feedbackToast({
          variant: 'success',
          message: TaskToastSuccessMessage,
          ctaMessage: 'Rückgängig machen?',
          toastId: 'TaskAdded'
        });
        setInputFields(initialInputFieldsState);
      })
      .catch(error => {
        let TaskToastErrorMessage = 'Dieses Ämtli gibt es bereits.';
        if (error.response && error.response.data) {
          const errorCode = error.response.data.message;
          if (errorCode === 1001) {
            TaskToastErrorMessage = 'Dieses Ämtli wurde gelöscht.';
          } else if (errorCode === 1002) {
            TaskToastErrorMessage = 'Dieses Ämtli gibt es bereits.';
          }
        }
        feedbackToast({
          variant: 'warn',
          message: TaskToastErrorMessage,
          toastId: 'TaskNotAdded'
        });
      });
  };

  useEffect(() => {
    const { taskName, requiredPersonnel, weekday, rhythm, duration } = inputFields;
    const isComplete =
      taskName.trim() !== '' &&
      requiredPersonnel.trim() !== '' &&
      weekday.trim() !== '' &&
      duration.trim() !== '' &&
      rhythm.trim() !== '';

    setIsFormComplete(isComplete);
  }, [inputFields]);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setInputFields(prevFields => ({
      ...prevFields,
      [name]: value
    }));

    if (name === 'rhythm') {
      setShowWeekdayDropdown(value !== '0 0 * * *');
      if (value === '0 0 * * *') {
        setInputFields(prevFields => ({
          ...prevFields,
          weekday: dayjs().day() === 0 ? '7' : dayjs().day().toString()
        }));
      }
    }
  };

  return (
    <div className="flex justify-center items-center">
      <div className="w-full max-w-4xl px-4 pt-2 mt-8 sm:p-6 lg:p-8 p-6 bg-theme-300 rounded-2xl shadow-lg relative">
        <div className="absolute top-4 right-4">
          <ExitButton onClick={onClose} />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 my-3 select-none">
          <InputField
            type="text"
            label="Name des Ämtlis"
            placeholder="Name hinzufügen..."
            size={Size.OneLine}
            onChange={handleInputChange}
            value={inputFields.taskName}
            name="taskName"
          />
          <Dropdown
            label="Rhythmus"
            id="rhythm"
            name="rhythm"
            options={[
              { value: '0 0 * * *', label: 'jeden Tag' },
              { value: '0 0 * * 0', label: 'jede Woche' },
              { value: '0 0 * * 0/2', label: 'alle 2 Wochen' }
            ]}
            onChange={handleInputChange}
            value={inputFields.rhythm}
          />
          <Dropdown
            label="Anzahl Personen"
            id="requiredPersonnel"
            name="requiredPersonnel"
            options={[
              { value: '1', label: '1' },
              { value: '2', label: '2' },
              { value: '3', label: '3' }
            ]}
            onChange={handleInputChange}
            value={inputFields.requiredPersonnel}
          />
          <Dropdown
            label="Dauer (in Min)"
            id="duration"
            name="duration"
            options={Array.from({ length: 12 }, (_, i) => ({ value: `${(i + 1) * 5}`, label: `${(i + 1) * 5}` }))}
            onChange={handleInputChange}
            value={inputFields.duration}
          />
          {showWeekdayDropdown && (
            <Dropdown
              label="Wochentag"
              id="weekday"
              name="weekday"
              options={[
                { value: '1', label: 'Montag' },
                { value: '2', label: 'Dienstag' },
                { value: '3', label: 'Mittwoch' },
                { value: '4', label: 'Donnerstag' },
                { value: '5', label: 'Freitag' }
              ]}
              onChange={handleInputChange}
              value={inputFields.weekday}
            />
          )}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-1 gap-4 mt-6">
          <InputField
            type="textarea"
            label="Beschreibung"
            placeholder="Beschreibung hinzufügen..."
            size={Size.MultipleLines}
            onChange={handleInputChange}
            value={inputFields.description}
            name="description"
          />
          {isFormComplete && <SaveButton onClick={handleSubmit} />}
        </div>
      </div>
    </div>
  );
};

export default TaskPopup;
