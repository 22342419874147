import { jwtDecode } from 'jwt-decode';
import { UserRole } from '../common';
import Cookies from 'js-cookie';

interface JwtPayload {
    provider: string;
    id: string;
    email: string;
    firstName: string;
    familyName: string;
    gtoken: string;
    roles: UserRole[];
    location: string[];
}

export function decodeToken (token: string): JwtPayload | null {
  let decoded: JwtPayload | null = null;  

  try {
    decoded = jwtDecode<JwtPayload>(token);
  } catch (error) {
    console.error('Error decoding token:', error);
  }  

  return decoded;
}

export function getAccessToken () {
  return Cookies.get('access_token');
}
