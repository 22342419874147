import axios from 'axios';
import dayjs from 'dayjs';
import { SchoolDay, Task, ScheduledTask, StrippedTask, Incapability } from '../domains';
import { User } from './auth';
import utc from 'dayjs/plugin/utc'
import { getAccessToken } from '../../utils';
dayjs.extend((utc))

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL || ''}/api`,
  headers: {
    Authorization: `Bearer ${getAccessToken()}`
  }
})

export const useAbsence = () => {
  const getMyAbsences = () => {
    return apiClient.get<Date[]>('absence/me')
      .then(response => response.data)
  };

  const getAbsences = (apprenticeId: string) => {
    return apiClient.get<Date[]>(`absence/${apprenticeId}`)
      .then(response => response.data)
  };

  const addAbsence = (apprenticeId: string, date: Date) => {
    return apiClient.patch(`absence/${apprenticeId}`, {
      date
    })
      .then(response => response.data)
  };

  // Updates all absences of the apprentice
  const updateApprenticeAbsences = (apprenticeId: string, dates: Date[]) => {
    return apiClient.put(`absence/${apprenticeId}`, {absences: dates.map(date => dayjs(date).utcOffset(0).startOf('day').add(1, 'day').toDate())})
      .then(response => response.data);
  };
  return {
    getMyAbsences,
    getAbsences,
    addAbsence,
    updateApprenticeAbsences
  };
}

export const useSchoolDay = () => {
  const getMySchoolDays = () => {
    return apiClient.get<SchoolDay>('/schoolDay/me')
      .then(response => response.data)
  };

  const getSchoolDays = (apprenticeId: string) => {
    return apiClient.get<SchoolDay>(`/schoolDay/${apprenticeId}`)
      .then(response => response.data)
  };

  const updateSchoolDays = (schoolDay: SchoolDay) => {
    return apiClient.put<SchoolDay>('/schoolDay/', {...schoolDay, exemptions: schoolDay.exemptions.map(date => dayjs(date).utcOffset(0).startOf('day').add(1, 'day').toDate())})
      .then(response => response.data)
  };

  return {
    getMySchoolDays,
    getSchoolDays,
    updateSchoolDays
  }
}

export const useTask = () => {
  const createTask = (task: Task) => {
    return apiClient.post<Task>('task', task)
      .then(response => response.data);
  };

  const updateTask = (task: Task) => {
    return apiClient.put<Task>('task', task)
      .then(response => response.data);
  };

  const deleteTask = (taskId: number | undefined) => {
    return apiClient.delete<void>(`task/${taskId}`)
  };

  const getAllTasks = () => {
    return apiClient.get<StrippedTask[]>('task')
      .then(response => {
        return response.data;
      });
  };

  return {
    createTask,
    updateTask,
    deleteTask,
    getAllTasks
  };
};

export const useIncapabilities = () => {
  const getIncapabilities = (userId: string) => {
    return apiClient.get<Incapability[]>(`incapabilities/${userId}`)
      .then(response => response.data)
  }

  const createIncapabilities = (userId: string, taskId: number) => {
    return apiClient.post<Incapability>('incapabilities', null, {params: {userId, taskId}})
  }

  const deleteIncapabilities = (userId: string, taskId: number) => {
    return apiClient.delete<void>('incapabilities', {params: {userId, taskId}})
  }

  return {
    getIncapabilities,
    createIncapabilities,
    deleteIncapabilities
  }
}

export const useSchedule = () => {
  const getSchedule = () => {
    return apiClient.get<ScheduledTask[]>('schedule')
      .then(response => {
        return response.data.map(task => {
          return {
            ...task,
            date: dayjs(task.date).toDate()
          }
        })
      })
  };

  return {
    getSchedule
  }
}

export const usePersonnel = () => {
  // Returns a list of emails of available but not registered,
  // only used for autocompletion.
  const getUnregisteredPersonnel = () => {
    return apiClient.get<string[]>('personnel')
      .then(response => response.data)
  };

  return {
    getUnregisteredPersonnel
  }
}

export const useApprentice = () => {
  const getApprentices = () => {
    return apiClient.get<User[]>('apprentice')
      .then(response => response.data)
  };

  const addApprentice = (email: string) => {
    return apiClient.post('apprentice', {email})
      .then(response => response.data)
  };

  const deleteApprentice = (email: string) => {
    return apiClient.put(`apprentice`, {email, isDeleted: true})
      .then(response => response.data);
  };

  return {
    getApprentices,
    addApprentice,
    deleteApprentice
  }
}

export const useFeedback = () => {

  const getStatisticsFeedback = (
    idTask?: number,
    revieweeUserId?: string,
    date?: Date
  ) => {
    return apiClient.get('feedback/statistics', {
      params: {
        taskId: idTask,
        revieweeUserId: revieweeUserId,
        date: date
      }
    })
      .then(response => response.data)
  }

  const getFeedback = (
    idTask: number,
    reviewerUserId: string,
    revieweeUserId: string,
    date: Date
  ) => {
    return apiClient.get('feedback', {
      params: {
        taskId: idTask,
        reviewerUserId: reviewerUserId,
        revieweeUserId: revieweeUserId,
        date: date
      }
    })
      .then(response => response.data)
  }

  const sendFeedback = (feedback: {
    idTask: number,
    revieweeUserId: string,
    comment: string,
    rating: number,
    date: Date
  }) => {
    return apiClient.put('feedback', feedback)
      .then(response => response.data)
  }

  return {
    getFeedback,
    sendFeedback,
    getStatisticsFeedback
  }
}

