import React from 'react';
import { TrashIcon } from '@heroicons/react/16/solid';
import { StrippedTask, User } from '../../common';

interface ItemListProps {
  list: StrippedTask[] | User[];
  onEdit?: (index: number) => void;
  onDelete?: (index: number) => void;
  emptyListNotice: string;
}

const ItemList = ({
  list,
  onEdit,
  onDelete,
  emptyListNotice
}: ItemListProps) => {
  const isUser = (item: StrippedTask | User): item is User => {
    return (item as User).userId !== undefined;
  };

  return (
    <div className="flex flex-wrap gap-2 mt-4">
      {list.length === 0 ? (
        <div className="px-5 py-2 font-primary text-theme-300/50 rounded-lg border-theme-300/50 border-dashed border select-none">
          {emptyListNotice}
        </div>
      ) : (
        list.map((item, index) => (
          <div
            key={index}
            className="relative min-w-40 bg-theme-200 px-5 py-3 font-primary text-white rounded-lg shadow-sm border-solid border-theme-300/25 border select-none group hover:text-opacity-0 flex items-center justify-center"
          >
            {isUser(item) ? item.userName : item.name}
            {(onEdit || onDelete) && (
              <div className="absolute inset-0 flex items-center justify-between gap-1 opacity-0 group-hover:opacity-100 transition-opacity px-2">
                {onEdit && (
                  <button
                    className={`${
                      onDelete ? 'flex-grow' : 'w-full'
                    } bg-theme-300 flex justify-center border-theme-50/50 shadow-md border text-white py-1 px-2 rounded-md hover:bg-theme-300/50`}
                    onClick={() => onEdit(index)}
                  >
                    Bearbeiten
                  </button>
                )}
                {onDelete && (
                  <button
                    className={`${
                      onEdit ? 'w-9' : 'w-full'
                    } bg-theme-300 border-theme-50/50 shadow-md border text-white py-1 rounded-md flex items-center justify-center hover:bg-red-500/50 transition-all`}
                    onClick={() => onDelete(index)}
                  >
                    <TrashIcon className="h-6 text-theme-50" />
                  </button>
                )}
              </div>
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default ItemList;
