import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import ProtectedRoute from './protectedRoute';
import Login from './login/page';
import Absence from './absence/page';
import Calendar from './calendar/page';
import Forbidden from './forbidden/page';
import CreateNewTaskView from './tasks/page';
import AddApprentice from './addApprentice/page';
import { HomePage } from './home/page';
import { NotFoundPage } from './not-found/page';
import { UserRole } from '../common';
import { AppHeader } from './header';
import { Toaster } from 'react-hot-toast';
import { Statistics } from './statistics/page';

const App = () => {
  const location = useLocation();

  const isLoginPage = location.pathname === '/login';

  return (
    <div className='flex flex-col h-screen'>
      {!isLoginPage && <AppHeader />}
      <Routes>
        <Route
          path='/login'
          element={<Login />}
        />
        <Route
          path='/'
          element={<ProtectedRoute children={<HomePage />} requiredRoles={[UserRole.Apprentice, UserRole.Educator]} />}
        />
        <Route
          path='/absence'
          element={<ProtectedRoute children={<Absence />} requiredRoles={[UserRole.Educator]} />}
        />
        <Route
          path='/task'
          element={<ProtectedRoute children={<CreateNewTaskView />} requiredRoles={[UserRole.Educator]} />}
        />
        <Route
          path='/calendar'
          element={<ProtectedRoute children={<Calendar />} requiredRoles={[UserRole.Apprentice]} />}
        />
        <Route
          path='/apprentice'
          element={<ProtectedRoute children={<AddApprentice />} requiredRoles={[UserRole.Educator]} />}
        />
        <Route
          path='/statistic'
          element={<ProtectedRoute children={<Statistics />} requiredRoles={[UserRole.Educator]} />}
        />
        <Route
          path='*'
          element={<NotFoundPage />} />
        <Route
          path='/forbidden'
          element={<Forbidden />} />
      </Routes>

      <Toaster
        position='bottom-center'
        toastOptions={{
          className: '',
          duration: 5000,
          style: {
            background: '#f4f4f4',
            color: '#38533d',
            boxShadow: 'unset',
            outline: '1px solid #9B9B9B'
          }
        }}
      />
    </div>
  );
};

export default App;
