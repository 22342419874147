import React, { useState } from 'react';
import ApprenticeOverview from './apprenticeOverview';
import NewApprenticePopup from './newApprenticePopup';

const ApprenticePage = () => {
  const [isNewApprenticePopupVisible, setIsNewApprenticePopupVisible] = useState(false);
  const [isEditApprenticePopupVisible, setIsEditApprenticePopupVisible] = useState(false)

  const toggleNewPopup = () => {
    setIsNewApprenticePopupVisible(!isNewApprenticePopupVisible);
  };

  const toggleEditPopup = () => {
    setIsEditApprenticePopupVisible(!isEditApprenticePopupVisible);
  };

  return (
    <div className="bg-theme-50 h-full">
      {isNewApprenticePopupVisible ? (
        <NewApprenticePopup onClose={toggleNewPopup} />
      ) : (
        <ApprenticeOverview onNewApprenticeClick={toggleNewPopup} onEditApprenticeClick={toggleEditPopup} setIsEditPopupVisible={setIsEditApprenticePopupVisible} isEditPopupVisible={isEditApprenticePopupVisible}/>
      )}
    </div>
  );
};

export default ApprenticePage;
