import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';
import UserSearch from '../components/userSearch';
import {
  Task,
  useApprentice,
  User,
  useTask,
  useSchedule,
  useFeedback
} from '../../common';
import TaskSearch from '../components/taskSearch';
import { TimeFrame } from '../../common/enums/timeFrameFilterOptions';
import BarChart, { DataEntry } from '../components/barChart';
import RatingLineChart from './ratingLineChart';
import { FeedbackList } from './feedbackList';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

export const Statistics = () => {
  const [isToggled, setIsToggled] = useState(false);
  const [apprentices, setApprentices] = useState<User[]>([]);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [selectedApprentice, setSelectedApprentice] = useState<
    User | undefined
  >();
  const [selectedTask, setSelectedTask] = useState<Task | undefined>();
  const [searchedPersonnel, setSearchedPersonnel] = useState<string>('');
  const [searchedTask, setSearchedTask] = useState<string>('');
  const [selectedTimeFrame, setSelectedTimeFrame] = useState<TimeFrame>(
    TimeFrame.Week
  );
  const [frequencyData, setFrequencyData] = useState<DataEntry[]>([]);
  const [ratingData, setRatingData] = useState<DataEntry[]>([]);
  const [barChartLoading, setBarChartLoading] = useState<boolean>(true);
  const [clickedBar, setClickedBar] = useState<{
    name: string;
    chartType: 'frequency' | 'rating';
  } | null>(null);
  const [lineChartLoading, setLineChartLoading] = useState<boolean>(true);
  const [lineChartData, setLineChartData] = useState<
    { date: string; rating: number | null }[]
  >([]);
  const [filteredFeedbacks, setFilteredFeedbacks] = useState<
    { reviewerEmail: string; comment: string; date: string; rating: number }[]
  >([]);

  const { getApprentices } = useApprentice();
  const { getAllTasks } = useTask();
  const { getSchedule } = useSchedule();
  const { getStatisticsFeedback } = useFeedback();

  const getWeekday = (date: string) => dayjs(date).format('dd').toUpperCase();
  const getMonthAbbr = (date: string) => dayjs(date).format('MMM');

  useEffect(() => {
    getApprentices().then(setApprentices);
    getAllTasks().then(setTasks);
  }, []);

  useEffect(() => {
    setSelectedTask(undefined);
    setSearchedTask('');
    setSelectedApprentice(undefined);
    setSearchedPersonnel('');
    setLineChartData([]);
    setFilteredFeedbacks([]);
  }, [isToggled]);

  const handlePersonSelect = (selectedEmail: string) => {
    const apprentice = apprentices.find((appr) => appr.userEmail === selectedEmail);
    setSelectedApprentice(apprentice);
  };

  const handleTaskSelect = (selectedTaskName: string) => {
      const task = tasks.find((task) => task.name === selectedTaskName);
    setSelectedTask(task);
  };

  const getDateRange = () => {
    const now = dayjs();
    const timeFrames: Record<TimeFrame, dayjs.OpUnitType> = {
      [TimeFrame.Week]: 'week',
      [TimeFrame.Month]: 'month',
      [TimeFrame.Year]: 'year'
    };
    const selectedRange = timeFrames[selectedTimeFrame];
    if (!selectedRange) return {};

    const startDate = now.startOf(selectedRange);
    const endDate = now.endOf(selectedRange);

    return { startDate, endDate };
  };

  const fetchData = async () => {
    setBarChartLoading(true);

    const { startDate, endDate } = getDateRange();
    const scheduledTasks = await getSchedule();

    const tasksInTimeFrame = scheduledTasks.filter((task) => {
      const taskDate = dayjs(task.date);
      return (
        taskDate.isSameOrAfter(startDate) && taskDate.isSameOrBefore(endDate)
      );
    });

    let frequencyChartData: { name: string; rating: number }[] = [];
    if (selectedApprentice) {
      const taskCounts = tasksInTimeFrame
        .filter((task) =>
          task.apprentices.some(
            (appr) => appr.userId === selectedApprentice.userId
          )
        )
        .reduce((acc: Record<string, number>, task) => {
          acc[task.taskName] = (acc[task.taskName] || 0) + 1;
          return acc;
        }, {});

      frequencyChartData = Object.entries(taskCounts).map(([name, rating]) => ({
        name,
        rating
      }));
    } else if (selectedTask) {
      const apprenticeCounts = tasksInTimeFrame
        .filter((task) => task.taskName === selectedTask.name)
        .reduce((acc: Record<string, number>, task) => {
          task.apprentices.forEach((apprentice) => {
            acc[apprentice.userName] = (acc[apprentice.userName] || 0) + 1;
          });
          return acc;
        }, {});

      frequencyChartData = Object.entries(apprenticeCounts).map(
        ([name, rating]) => ({
          name,
          rating
        })
      );
    }

    const taskRatingsMap: Record<string, number[]> = {};
    if (selectedApprentice) {
      const feedbackPromises = tasksInTimeFrame
        .filter((task) =>
          task.apprentices.some(
            (appr) => appr.userId === selectedApprentice.userId
          )
        )
        .map(async (task) => {
          const feedback = await getStatisticsFeedback(
            task.taskId,
            selectedApprentice.userId,
            task.date
          );

          if (Array.isArray(feedback) && feedback.length > 0) {
            if (!taskRatingsMap[task.taskName]) {
              taskRatingsMap[task.taskName] = [];
            }
            taskRatingsMap[task.taskName].push(
              ...feedback.map((entry) => entry.rating)
            );
          }
        });

      await Promise.all(feedbackPromises);
    } else if (selectedTask) {
      const feedbackPromises = tasksInTimeFrame
        .filter((task) => task.taskName === selectedTask.name)
        .flatMap((task) =>
          task.apprentices.map(async (apprentice) => {
            const feedback = await getStatisticsFeedback(
              selectedTask.taskId,
              apprentice.userId,
              task.date
            );

            if (Array.isArray(feedback) && feedback.length > 0) {
              if (!taskRatingsMap[apprentice.userName]) {
                taskRatingsMap[apprentice.userName] = [];
              }
              taskRatingsMap[apprentice.userName].push(
                ...feedback.map((entry) => entry.rating)
              );
            }
          })
        );

      await Promise.all(feedbackPromises);
    }

    const feedbackData = Object.entries(taskRatingsMap).map(
      ([name, ratings]) => {
        const totalRating = ratings.reduce((sum, rating) => sum + rating, 0);
        const averageRating = +(totalRating / ratings.length).toFixed(1);

        return { name, rating: averageRating };
      }
    );

    setFrequencyData(frequencyChartData);
    setRatingData(feedbackData);
    setBarChartLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [selectedApprentice, selectedTask, selectedTimeFrame]);

  const fillWeekData = (
    data: { date: string; rating: number }[]
  ): { date: string; rating: number | null }[] => {
    const daysOfWeek = ['MO', 'DI', 'MI', 'DO', 'FR'];
    const filledData: { date: string; rating: number | null }[] =
      daysOfWeek.map((day) => ({
        date: day,
        rating: null
      }));

    data.forEach((entry) => {
      const dayIndex = daysOfWeek.indexOf(entry.date);
      if (dayIndex !== -1) {
        filledData[dayIndex].rating = entry.rating;
      }
    });

    return filledData;
  };

  const fillMonthData = (
    data: { date: string; rating: number }[],
    month: number
  ): { date: string; rating: number | null }[] => {
    const monthStart = dayjs().month(month).startOf('month');
    const monthEnd = monthStart.endOf('month');
    const weeksInMonth: { date: string; rating: number | null }[] = [];

    let currentWeekStart = monthStart.startOf('week');

    while (currentWeekStart.isBefore(monthEnd)) {
      const weekLabel = `CW${currentWeekStart.week()}`;

      const weekData = data.find((entry) => entry.date === weekLabel);

      const averageRating = weekData ? weekData.rating : null;

      weeksInMonth.push({ date: weekLabel, rating: averageRating });

      currentWeekStart = currentWeekStart.add(1, 'week');
    }

    return weeksInMonth;
  };

  const fillYearData = (
    data: { date: string; rating: number }[]
  ): { date: string; rating: number | null }[] => {
    const monthPairs = [
      [0, 1],
      [2, 3],
      [4, 5],
      [6, 7],
      [8, 9],
      [10, 11]
    ];

    const monthMap: Record<string, number> = {
      JAN: 0,
      FEB: 1,
      MÄR: 2,
      APR: 3,
      MAI: 4,
      JUN: 5,
      JUL: 6,
      AUG: 7,
      SEPT: 8,
      OKT: 9,
      NOV: 10,
      DEZ: 11
    };

    const filledData: { date: string; rating: number | null }[] = [];

    for (const [firstMonth, secondMonth] of monthPairs) {
      const tickLabel = `${Object.keys(monthMap)[firstMonth]}/${
        Object.keys(monthMap)[secondMonth]
      }`;

      const ratingsInPair = data
        .filter((entry) => {
          const entryMonth = monthMap[entry.date.toUpperCase().slice(0, 3)];
          return entryMonth === firstMonth || entryMonth === secondMonth;
        })
        .map((entry) => entry.rating);

      const averageRating = ratingsInPair.length
        ? parseFloat(
            (
              ratingsInPair.reduce((sum, rating) => sum + rating, 0) /
              ratingsInPair.length
            ).toFixed(1)
          )
        : null;

      filledData.push({ date: tickLabel, rating: averageRating });
    }

    return filledData;
  };

  const mapFeedbacksWithTimeFrame = (
    data: { date: string; rating: number }[],
    timeframe: TimeFrame
  ): { date: string; rating: number | null }[] => {
    const aggregatedData: Record<string, { sum: number; count: number }> = {};

    data.forEach((entry) => {
      let key: string;

      switch (timeframe) {
        case TimeFrame.Week:
          key = getWeekday(entry.date);
          break;
        case TimeFrame.Month:
          key = `CW${dayjs(entry.date).week()}`;
          break;
        case TimeFrame.Year:
          key = getMonthAbbr(entry.date);
          break;
        default:
          key = entry.date;
      }

      if (!aggregatedData[key]) {
        aggregatedData[key] = { sum: 0, count: 0 };
      }

      aggregatedData[key].sum += entry.rating;
      aggregatedData[key].count += 1;
    });

    const transformedData = Object.entries(aggregatedData).map(
      ([key, { sum, count }]) => ({
        date: key,
        rating: parseFloat((sum / count).toFixed(1))
      })
    );

    switch (timeframe) {
      case TimeFrame.Week:
        return fillWeekData(transformedData);
      case TimeFrame.Month:
        return fillMonthData(transformedData, dayjs().month());
      case TimeFrame.Year:
        return fillYearData(transformedData);
      default:
        return transformedData;
    }
  };

  const handleBarClick = async (
    entry: DataEntry,
    chartType: 'frequency' | 'rating'
  ) => {
    setLineChartLoading(true);
    setClickedBar({ name: entry.name, chartType });
    const { startDate, endDate } = getDateRange();
    const scheduledTasks = await getSchedule();

    let feedbackData: {
      date: string;
      reviewerEmail: string;
      comment: string;
      rating: number;
    }[] = [];
    const feedbackPromises = scheduledTasks
      .filter((task) => {
        const taskDate = dayjs(task.date);
        return (
          taskDate.isSameOrAfter(startDate) &&
          taskDate.isSameOrBefore(endDate) &&
          (selectedTask ? task.taskName === selectedTask.name : true) &&
          (selectedApprentice
            ? task.apprentices.some(
                (appr) => appr.userId === selectedApprentice.userId
              )
            : true) &&
          (selectedApprentice
            ? task.taskName === entry.name
            : task.apprentices.some((appr) => appr.userName === entry.name))
        );
      })
      .map(async (task) => {
        const feedback = await getStatisticsFeedback(
          task.taskId,
          selectedApprentice?.userId || '',
          task.date
        );
        if (feedback && feedback.length > 0) {
          feedback.forEach((entry: any) => {
            feedbackData.push({
              date: task.date.toString(),
              reviewerEmail: entry.reviewer.userEmail,
              comment: entry.comment,
              rating: entry.rating
            });
          });
        }
      });

    await Promise.all(feedbackPromises);

    setFilteredFeedbacks(feedbackData);

    const lineChartData = mapFeedbacksWithTimeFrame(
      feedbackData.map((fb) => ({ date: fb.date, rating: fb.rating })),
      selectedTimeFrame
    );

    setLineChartData(lineChartData);
    setLineChartLoading(false);
  };

  const timeFrameButtonStyles = (label: TimeFrame) =>
    `flex justify-center items-center h-12 w-32 rounded-3xl shadow-md text-theme-50 cursor-pointer 
    ${
      selectedTimeFrame === label
        ? 'bg-theme-300'
        : 'bg-theme-100 hover:bg-theme-100/60 transition-opacity ease-out delay-175'
    }`;

  return (
    <div className="flex flex-col space-y-10 mx-44">
      <div className="flex justify-center text-3xl font-bold font-primary text-theme-300">
        Statistiken
      </div>
      <div className="flex flex-row space-x-36">
        <div className="flex flex-col w-full space-y-5">
          <div className="flex justify-center text-xl tracking-wide font-bold font-primary text-theme-300">
            Suchen nach...
          </div>
          <div className="flex justify-center">
            <div className="relative py-6 px-12 text-white rounded-3xl bg-theme-300">
              <div className="relative inline-block w-full h-16 mb-4 shadow-inner rounded-full">
                <input
                  type="checkbox"
                  id="toggle"
                  className="hidden"
                  checked={isToggled}
                  onChange={() => {
                    setIsToggled((prev) => !prev);
                  }}
                />

                <div className="flex items-center justify-between w-full h-full bg-theme-950 rounded-full border-theme-300 shadow-md">
                  <label
                    htmlFor="toggle"
                    className={`flex-1 z-30 mx-2 text-center text cursor-pointer rounded-full select-none py-3 shadow-inner ${
                      isToggled
                        ? 'rounded-full text-theme-200 border-theme-300 border'
                        : 'transition-opacity ease-in delay-175 rounded-full text-theme-950'
                    }`}
                  >
                    Person
                  </label>
                  <label
                    htmlFor="toggle"
                    className={`flex-1 z-20 mx-2 text-center text cursor-pointer rounded-full py-3 shadow-inner select-none ${
                      isToggled
                        ? 'rounded-full text-theme-950 transition-opacity ease-out delay-175'
                        : 'rounded-full text-theme-200 border-theme-300 border'
                    }`}
                  >
                    Ämtli
                  </label>
                  <div
                    className={`absolute z-10 w-1/2 h-full border-8 border-theme-950 bg-theme-50 rounded-full transition-transform duration-275 ease-in-out text-theme-300 shadow-inner ${
                      isToggled ? 'transform translate-x-full' : ''
                    }`}
                  ></div>
                </div>
              </div>
              <div className={`task ${isToggled ? 'hidden' : ''}`}>
                <UserSearch
                  emails={apprentices.map((a) => a.userEmail)}
                  value={searchedPersonnel}
                  onChange={(selectedPerson: string) => {
                    setSearchedPersonnel(selectedPerson);
                    handlePersonSelect(selectedPerson);
                    setLineChartData([]);
                    setFilteredFeedbacks([]);
                    setClickedBar(null);
                  }}
                />
              </div>
              <div className={`person ${isToggled ? '' : 'hidden'}`}>
                <TaskSearch
                  tasks={tasks.map((a) => a.name)}
                  value={searchedTask}
                  onChange={(selectedTaskName: string) => {
                    setSearchedTask(selectedTaskName);
                    handleTaskSelect(selectedTaskName);
                    setLineChartData([]);
                    setFilteredFeedbacks([]);
                    setClickedBar(null);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex justify-center text-xl tracking-wide font-bold font-primary text-theme-300">
            Zeitraum für Statistiken
          </div>
          <div className="flex flex-row space-x-5 justify-center items-center w-full h-full">
            {Object.values(TimeFrame).map((label) => (
              <div
                key={label}
                className={timeFrameButtonStyles(label)}
                onClick={() => {
                  setSelectedTimeFrame(label as TimeFrame);
                  setLineChartData([]);
                  setFilteredFeedbacks([]);
                  setClickedBar(null);
                }}
              >
                {label}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-row space-x-36">
        <div className="flex flex-col w-full space-y-5">
          <div className="flex justify-center text-xl tracking-wide font-bold font-primary text-theme-300">
            Häufigkeit der Durchführung
          </div>
          <div className="mr-16">
            <BarChart
              data={frequencyData}
              loading={barChartLoading}
              chartType="frequency"
              clickedBar={clickedBar}
              onClick={handleBarClick}
            />
          </div>
          {lineChartData.some((entry) => entry.rating !== null) && (
            <div className="flex flex-col w-full space-y-5 pb-5">
              <div className="flex justify-center text-xl tracking-wide font-bold font-primary text-theme-300">
                Detailansicht Bewertung
              </div>
              <div className="mr-16">
                <RatingLineChart
                  data={lineChartData}
                  loading={lineChartLoading}
                />
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col w-full space-y-5">
          <div className="flex justify-center text-xl tracking-wide font-bold font-primary text-theme-300">
            Durchschnittliche Bewertung
          </div>
          <div className="mr-16">
            <BarChart
              data={ratingData}
              chartType="rating"
              clickedBar={clickedBar}
              domain={{ minValue: 0, maxValue: 5 }}
              ticks={[0, 1, 2, 3, 4, 5]}
              loading={barChartLoading}
              onClick={handleBarClick}
            />
          </div>
          {filteredFeedbacks.length > 0 && (
            <div>
              <div className="flex justify-center text-xl tracking-wide font-bold font-primary text-theme-300">
                Kommentare
              </div>
              <FeedbackList feedbackData={filteredFeedbacks} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
