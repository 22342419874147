import React, { useState } from 'react';
import TaskOverview from './taskOverview';
import TaskPopup from './createTaskPopup';
import { Task } from '../../common';

const CreateNewTaskView = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  return (
    <div className="bg-theme-50 h-full">
      {isPopupVisible ? (
        <TaskPopup onClose={togglePopup} task={selectedTask} />
      ) : (
        <TaskOverview
          onNewTaskClick={togglePopup}
          setIsPopupVisible={setIsPopupVisible}
          isPopupVisible={isPopupVisible}
          setSelectedTask={setSelectedTask}
        />
      )}
    </div>
  );
};

export default CreateNewTaskView;
