import Modal from 'react-modal';
import {
  XMarkIcon,
  StarIcon as SolidStarIcon,
  ClipboardDocumentListIcon,
  PaperAirplaneIcon as SolidPaperAirplaneIcon
} from '@heroicons/react/24/solid';
import {
  CalendarDaysIcon,
  PaperAirplaneIcon as OutlinePaperAirplane,
  StarIcon as OutlineStarIcon
} from '@heroicons/react/24/outline';
import {useEffect, useState} from 'react';
import {useFeedback} from '../../common';
import {feedbackToast} from '../components/toasts';
import ExitButton from '../components/exitButton';

interface feedbackModalProps {
  modalIsOpen: boolean,
  closeModal: () => void,
  content:
    {
      reviewerId: string,
      reviewee: {
        name: string,
        id: string
      },
      task: {
        name: string
        id: number
      },
      date: Date
    }
}

const FeedbackModal = ({modalIsOpen, closeModal, content: {reviewerId, reviewee:{name: apprenticeName, id: apprenticeId}, task: {name: taskName, id: taskId}, date}}: feedbackModalProps) => {
  const [commentText, setCommentText] = useState<string>('')
  const [rating, setRating] = useState(0);
  const [loading, setLoading] = useState<boolean>(true)
  const [visualRating, setVisualRating] = useState(rating);
  const { getFeedback, sendFeedback } = useFeedback();

  useEffect(() => {
    getFeedback(taskId, reviewerId, apprenticeId, date).then(response=> {
      setCommentText(response.comment || '')
      setRating(response.rating ||0)
    })
      .catch(err=> {
        console.error(err)
      })
      .finally(() => setLoading(false))
  }, []);

  useEffect(() => {
    setVisualRating(rating)
  }, [rating]);

  const sendFeedbackOnClick = () => {
    if (!commentText.trim()) {
      feedbackToast({
        variant: 'error',
        message: 'Wieso diese Bewertung?',
        toastId: 'FeedbackEmptyComment'
      });
      return;
    }
    
    if (rating < 1) {
      feedbackToast({
        variant: 'error',
        message: 'Mindestens einen Stern ist verdient...',
        toastId: 'FeedbackEmptyRating'
      });
      return;
    }

    sendFeedback({
      'idTask': taskId,
      'revieweeUserId': apprenticeId,
      'comment': commentText,
      'rating': rating,
      'date': date
    }).then(() => {
      feedbackToast({
        variant: 'success',
        message: 'Feedback wurde versendet!',
        toastId: 'FeedbackSuccessfullySent'
      });
      closeModal();
    })
      .catch(err => console.error(err.response))
  }

  const monthNames = [
    'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
    'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'
  ];

  let stars = []

  for (let i = 0; i < 5; i++) {
    stars[i] = (
      <div
        key={i}
        onMouseEnter={() => setVisualRating(i + 1)}
        onClick={() => setRating(i + 1)}
      >
        {visualRating > i ?
          <SolidStarIcon className='size-6 text-orange-400'/> :
          <OutlineStarIcon className='size-6'/>
        }
      </div>
    )
  }

  return (
    <Modal
      isOpen={modalIsOpen && !loading}
      onRequestClose={closeModal}
      className='bg-theme-300 p-4 rounded-lg w-1/3 mx-auto shadow-lg my-20 outline-none text-theme-50'
      overlayClassName="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
    >
      <div className='container pt-1'>
        <div className=' grid grid-cols-12'>
          <div className='container col-span-11 pe-2'>
            <div className='flex justify-start'>
              <h2 className='text-xl font-bold'>Feedback an {apprenticeName}</h2>
            </div>
            <div className='my-4 grid grid-rows-3 gap-2'>
              <div className='flex flex-row gap-2 justify-start items-center'>
                <ClipboardDocumentListIcon className='size-8'/>
                <div className='text-lg font-medium'>
                  {taskName}
                </div>
              </div>
              <div className='flex flex-row gap-2 justify-start items-center'>
                <CalendarDaysIcon className='size-8'/>
                <div className='text-lg font-medium'>
                  {`${date.getDate()}. ${monthNames[date.getMonth()]}`}
                </div>
              </div>
              <div
                onMouseLeave={() => setVisualRating(rating)}
                className='flex flex-row gap-2 items-center ps-1 cursor-pointer'>
                {stars}
              </div>
            </div>
            <textarea
              className='w-full h-28 p-2 rounded-lg text-theme-950 text-opacity-75 focus:outline-none focus:ring-4 ring-offset-1 ring-theme-300 ring-opacity-20 resize-none'
              placeholder='Kommentiere...'
              value={commentText}
              onChange={event => setCommentText(event.target.value)}
            />
          </div>
          <div className='grid content-between justify-items-center'>
            <ExitButton onClick={closeModal} />
            <button className='size-10 rounded-full flex justify-center items-center group' onClick={sendFeedbackOnClick}>
              <OutlinePaperAirplane className='size-8 group-hover:hidden'/>
              <SolidPaperAirplaneIcon className='size-8 hidden group-hover:block'/>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default FeedbackModal;
