import React from 'react';
import { useAuth } from '../../common';

function Login () {
  const { login } = useAuth();

  return (
    <div className="h-screen w-screen bg-theme-300 flex justify-center items-center overflow-hidden">
      <div className="absolute inset-0">
        <div className="h-full w-full bg-repeat" style={{backgroundImage: 'url(/images/loginBgImage.png)'}}/>
      </div>
      <div className="text-center z-10 pt-10 bg-black bg-opacity-30 rounded-2xl backdrop-blur-sm py-14 px-10">
        <h2 className="text-white font-secondary text-5xl font-bold mb-8">Ämtliplan</h2>
        <button
          className="px-14 py-2 bg-white text-theme-300 rounded-lg shadow-lg
          hover:bg-theme-50 justify-center items-center flex"
          onClick={() => login()}>
          <img src='/images/googleIcon.svg' className="w-6 h-6 mr-2"  alt="Google Logo"/>
          Continue with Google
        </button>
      </div>
    </div>
  );
}

export default Login;
