import React, {useEffect, useState} from 'react';
import {DayPicker} from 'react-day-picker';
import {de} from 'date-fns/locale';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import SaveButton from '../components/saveButton';
import UserSearch from '../components/userSearch';
import {SchoolDay, useAbsence, useApprentice, User, useSchoolDay} from '../../common';
import { feedbackToast } from '../components/toasts';
import WeekDayPicker from './weekDayPicker';
import 'react-day-picker/dist/style.css';

dayjs.extend(utc);

const Absence = () => {
  const [selectedApprentice, setSelectedApprentice] = useState<User | undefined>();
  const [selectedDates, setSelectedDates] = useState<Date[] | undefined>([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [, setAbsences] = useState<Date[]>([]);
  const [schoolDay, setSchoolDay] = useState<SchoolDay | undefined>();
  const [apprentices, setApprentices] = useState<User[]>([]);
  const [isToggled, setIsToggled] = useState(false);
  const [searchedPersonnel, setSearchedPersonnel] = useState<string>('');
  const [unsavedSchoolDayExemptions, setUnsavedSchoolDayExemptions] = useState<{newExemptions: Date[], removedExemptions: Date[] }>({
    newExemptions: [],
    removedExemptions: []
  });

  const { getApprentices } = useApprentice();
  const { updateApprenticeAbsences, getAbsences } = useAbsence();
  const { getSchoolDays,  updateSchoolDays } = useSchoolDay();

  useEffect(() => {
    getApprentices().then(a => setApprentices(a));
  }, []);

  useEffect(() => {
    if (selectedApprentice) {
      Promise.all([
        getAbsences(selectedApprentice.userId),
        getSchoolDays(selectedApprentice.userId)
        // eslint-disable-next-line complexity
      ]).then(([loadedAbsences, loadedSchoolDay])=> {
        let startDate = dayjs( new Date(2024, 7, 1));
        const endDate = dayjs(new Date(2028, 6, 31));
        const newSelectedDates : Date[] = [];

        while (startDate.isBefore(endDate, 'day')) {
          const day = startDate.day();
          const isAbsent = loadedAbsences.some(a => dayjs(a).isSame(startDate, 'day'));
          const isSchoolDayExemption = loadedSchoolDay.exemptions.some((exemption: Date) =>
            dayjs(exemption).isSame(startDate, 'day'));

          switch (day) {
          case 1:
            if (loadedSchoolDay.schoolDays.isMonAvailable) {
              if (isAbsent) {
                newSelectedDates.push(startDate.toDate());
              }
            } else {
              if (!isSchoolDayExemption) {
                newSelectedDates.push(startDate.toDate());
              }
            }
            break;
          case 2:
            if (loadedSchoolDay.schoolDays.isTueAvailable) {
              if (isAbsent) {
                newSelectedDates.push(startDate.toDate());
              }
            } else {
              if (!isSchoolDayExemption) {
                newSelectedDates.push(startDate.toDate());
              }
            }
            break;
          case 3:
            if (loadedSchoolDay.schoolDays.isWedAvailable) {
              if (isAbsent) {
                newSelectedDates.push(startDate.toDate());
              }
            } else {
              if (!isSchoolDayExemption) {
                newSelectedDates.push(startDate.toDate());
              }
            }
            break;
          case 4:
            if (loadedSchoolDay.schoolDays.isThuAvailable) {
              if (isAbsent) {
                newSelectedDates.push(startDate.toDate());
              }
            } else {
              if (!isSchoolDayExemption) {
                newSelectedDates.push(startDate.toDate());
              }
            }
            break;
          case 5:
            if (loadedSchoolDay.schoolDays.isFriAvailable) {
              if (isAbsent) {
                newSelectedDates.push(startDate.toDate());
              }
            } else {
              if (!isSchoolDayExemption) {
                newSelectedDates.push(startDate.toDate());
              }
            }
            break;
          default:
            break;
          }

          startDate = dayjs(startDate.add(1, 'day'))
        }

        setAbsences(loadedAbsences.map(absence => dayjs(absence).utcOffset(0).toDate()));
        setSchoolDay(loadedSchoolDay);
        setSelectedDates(newSelectedDates);
      })
    }
  }, [selectedApprentice]);

  const toggleSwitch = () => {
    setIsToggled(!isToggled);
  };

  const handlePersonSelect = (selectedPerson: string) => {
    const apprentice = apprentices.find(a => a.userEmail === selectedPerson);
    setSelectedApprentice(apprentice);
    setUnsavedChanges(false);
    if (apprentice == undefined) {
      setSelectedDates([])
    }
  };

  const isWeekend = (date: Date) => {
    const day = date.getDay();
    return day === 0 || day === 6;
  };

  const handleSaveButtonClick = () => {
    if (!selectedApprentice || !schoolDay) return;
    const newAbsences = selectedDates?.filter(selectedDate => {
      const day = dayjs(selectedDate).day();

      switch (day) {
      case 1:
        return schoolDay.schoolDays.isMonAvailable;
      case 2:
        return schoolDay.schoolDays.isTueAvailable;
      case 3:
        return schoolDay.schoolDays.isWedAvailable;
      case 4:
        return schoolDay.schoolDays.isThuAvailable;
      case 5:
        return schoolDay.schoolDays.isFriAvailable;
      default:
        return true;
      }
    }) || [];

    updateApprenticeAbsences(selectedApprentice.userId, newAbsences)
      .then(() => {
        feedbackToast({
          variant: 'success',
          message: 'Änderungen erfolgreich gespeichert.',
          ctaMessage: 'Rückgängig machen?',
          toastId: 'ChangesSaved'
        });
        setUnsavedChanges(false);
        setAbsences(newAbsences)
      })
      .catch(() => {
        feedbackToast({
          variant: 'error',
          message: 'Fehler beim Speichern.',
          toastId: 'ChangesNotSaved'
        });
        setUnsavedChanges(false);
      });

    const updatedExemptions = getUpdatedExemptions()
    updateSchoolDays({
      ...schoolDay!,
      userId: selectedApprentice.userId,
      exemptions: updatedExemptions
    }).then(() => {
      feedbackToast({
        variant: 'success',
        message: 'Änderungen erfolgreich gespeichert.',
        ctaMessage: 'Rückgängig machen?',
        toastId: 'ChangesSaved'
      });
      setUnsavedChanges(false);
      setSchoolDay({...schoolDay, exemptions: updatedExemptions})
      setUnsavedSchoolDayExemptions({newExemptions:[], removedExemptions:[]})
    })
      .catch(() => {
        feedbackToast({
          variant: 'error',
          message: 'Fehler beim Speichern.',
          toastId: 'ChangesNotSaved'
        });
        setUnsavedChanges(false);
      });
  }

  const isSchoolDay = (dateToCheck: Date) => {
    const day = dayjs(dateToCheck).day();
    switch (day) {
    case 1:
      return !schoolDay!.schoolDays.isMonAvailable;
    case 2:
      return !schoolDay!.schoolDays.isTueAvailable;
    case 3:
      return !schoolDay!.schoolDays.isWedAvailable;
    case 4:
      return !schoolDay!.schoolDays.isThuAvailable;
    case 5:
      return !schoolDay!.schoolDays.isFriAvailable;
    default:
      return true;
    }
  }

  const updateUnsavedSchoolDayExemptions = (newSelectedDates: Date[] | undefined) => {
    //left outer join to determine a new schoolday exemption
    const newSelectedDatesSet = new Set(newSelectedDates?.map(d => d.toISOString()))
    const localSelectedDates = selectedDates ? selectedDates.filter(d => !newSelectedDatesSet.has(d.toISOString())) : [];
    const localNewExemptions = localSelectedDates.filter(d => isSchoolDay(d));
    const newExemptionsSet = new Set(unsavedSchoolDayExemptions.newExemptions.map(d => d.toISOString()));
    const filteredNewExemptions = localNewExemptions.filter(d => !newExemptionsSet.has(d.toISOString()));

    //right outer join to determine a new selected date which could be a schoolday exemption to be removed
    const selectedDatesSet = new Set(selectedDates?.map(d => d.toISOString()));
    const newlyAddedSelectedDates = newSelectedDates ?
      newSelectedDates.filter(d => !selectedDatesSet.has(d.toISOString())) :
      [];
    const localRemovedExemptions = newlyAddedSelectedDates.filter(d => isSchoolDay(d));
    const removedExemptionsSet = new Set(unsavedSchoolDayExemptions.removedExemptions.map(d => d.toISOString()));
    const filteredRemovedExemptions = localRemovedExemptions.filter(d => !removedExemptionsSet.has(d.toISOString()));

    const finalNewExemptions = unsavedSchoolDayExemptions.newExemptions.filter(d =>
      !filteredRemovedExemptions.some(removed => removed.toISOString() === d.toISOString()));

    const finalRemovedExemptions = unsavedSchoolDayExemptions.removedExemptions.filter(d =>
      !filteredNewExemptions.some(add => add.toISOString() === d.toISOString()));

    setUnsavedSchoolDayExemptions({
      newExemptions: [...filteredNewExemptions, ...finalNewExemptions],
      removedExemptions: [...filteredRemovedExemptions, ...finalRemovedExemptions]
    });

    setSelectedDates(newSelectedDates);
  };

  const getUpdatedExemptions = (): Date[] => {
    if (!schoolDay) {
      return [];
    }
    const oldExemptionsWithoutRemovedOnes = schoolDay.exemptions.filter(
      oldEx =>
        !unsavedSchoolDayExemptions.removedExemptions.some(e => dayjs(e).isSame(oldEx, 'day'))
    );

    return [...oldExemptionsWithoutRemovedOnes, ...unsavedSchoolDayExemptions.newExemptions];
  }

  return (
    <div className="flex flex-col items-center text-center bg-theme-50 h-full">
      <div className="relative py-6 px-12 text-white rounded-3xl bg-theme-300">
        <div className="relative inline-block w-96 h-16 mb-4 shadow-inner rounded-full">
          <input
            type="checkbox"
            id="toggle"
            className="hidden"
            checked={isToggled}
            onChange={toggleSwitch}
          />
          <div className="flex items-center justify-between w-full h-full bg-theme-950 rounded-full border-theme-300 shadow-md">
            <label
              htmlFor="toggle"
              className={`flex-1 z-30 mx-2 text-center text cursor-pointer rounded-full select-none py-3 shadow-inner ${isToggled ?
                'rounded-full text-theme-200 border-theme-300 border' : 'transition-opacity ease-in delay-175 rounded-full text-theme-950 '}`}
            >
                Schultage
            </label>
            <label
              htmlFor="toggle"
              className={`flex-1 z-20 mx-2 text-center text cursor-pointer rounded-full
          py-3 shadow-inner select-none ${isToggled ? 'rounded-full text-theme-950 ' +
                'transition-opacity ease-out delay-175 ' : 'rounded-full' +
                ' text-theme-200 border-theme-300 border '}`}
            >
                Absenz
            </label>
            <div
              className={`absolute z-10 w-1/2 h-full border-8 border-theme-950 bg-theme-50 rounded-full
          transition-transform duration-275 ease-in-out text-theme-300 shadow-inner shadow-lg
          ${isToggled ? 'transform translate-x-full' : ''}`}
            ></div>
          </div>
        </div>
        <div className={`absence ${isToggled ? '' : 'hidden'}`}>
          <div className="mb-4 text-2xl font-bold font-primary">
            Abwesenheit hinzufügen für...
          </div>
          <div className="absolute top-6 right-6">
          </div>
          <UserSearch
            emails={apprentices.map(a => a.userEmail)}
            value={searchedPersonnel}
            onChange={(selectedPerson: string) => {
              setSearchedPersonnel(selectedPerson);
              handlePersonSelect(selectedPerson);
            }}
          />
          <DayPicker
            mode="multiple"
            selected={selectedDates}
            onSelect={dates => {
              updateUnsavedSchoolDayExemptions(dates)
              setUnsavedChanges(true);
            }}
            numberOfMonths={3}
            disableNavigation={unsavedChanges}
            locale={de}
            modifiersClassNames={{
              selected: 'bg-theme-950 rounded-full'
            }}
            disabled={selectedApprentice?[isWeekend]:true}
            className="inline-block"
            style={{ position: 'static' }}
          />
          {unsavedChanges && <SaveButton onClick={handleSaveButtonClick} />}
        </div>
        <div className={`schoolday ${isToggled ? 'hidden' : ''}`}>
          <div className="mb-4 text-2xl font-bold font-primary">
            Schultage ändern für...
          </div>
          <div className="absolute top-6 right-6">
          </div>
          <UserSearch
            emails={apprentices.map(a => a.userEmail)}
            value={searchedPersonnel}
            onChange={(selectedPerson: string) => {
              setSearchedPersonnel(selectedPerson);
              handlePersonSelect(selectedPerson);
            }}
          />
          <WeekDayPicker selectedUserId={selectedApprentice?.userId!} selectedUserExemptions={schoolDay?.exemptions} />
          {unsavedChanges && isToggled && <SaveButton onClick={handleSaveButtonClick} />}
        </div>
      </div>
    </div>
  );
};

export default Absence;
