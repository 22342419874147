import React from 'react';

interface LoadingAnimationProps {
  message?: string;
}

const LoadingAnimation = ({ message='Diagrammdaten werden geladen...' }: LoadingAnimationProps) => {
  return (
    <div className="flex flex-col items-center justify-center p-5">
      <p className="mb-2 text-sm text-theme-300">{message}</p>
      <div className="flex space-x-2">
        <div className="w-5 h-10 bg-theme-300 rounded-lg animate-bounce" style={{animationDelay: '0s'}} />
        <div className="w-5 h-10 bg-theme-300 rounded-lg animate-bounce" style={{animationDelay: '0.15s'}}/>
        <div className="w-5 h-10 bg-theme-300 rounded-lg animate-bounce" style={{animationDelay: '0.3s'}}/>
      </div>
    </div>
  );
};

export default LoadingAnimation;
